
import React from "react";
import { contentBlockStyle } from "./layout";

const Modal = ({ isOpen, children }) => {

  function getModalState() {
    if(isOpen) {
      return 'z-50 bg-opacity-50';
    }
    else {
      return '-z-50 invisible bg-opacity-0';
    }
  }
  
  function getModalWindowState() {
    if(isOpen) {
      return 'opacity-100';
    }
    else {
      return '-translate-y-6 opacity-0';
    }
  }

  const bgClass = "fixed top-0 left-0 right-0 bottom-0 bg-opacity-50 bg-black " + 
                  "w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal " + 
                  "md:h-full transition ease-in-out duration-300 ";

  const windowClass = contentBlockStyle + " relative rounded-lg shadow transition " +
                      "ease-in-out duration-300 ";

  return (
  <div className={bgClass + getModalState()}>
    <div className="relative w-full h-full max-w-2xl md:h-auto mx-auto">
      <div className={windowClass + getModalWindowState()}>
        {children}
      </div>
    </div>
  </div>)
}

export default Modal;