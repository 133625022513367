
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import Modal from '../components/modal';
import PrivacyModal from '../components/privacy';
import { linkStyle, redLinkStyle, greenButtonStyle, grayButtonStyle } from "./layout";
import { inputStyle, labelStyle } from "./contactform";

const SUBMIT_STATE = {
  WAITING: '',
  PENDING: 'pending',
  SUCCESS: 'success',
}

const MailSignup = ({ isOpen, onClose }) => {

  const [privacyOpen, setPrivacyOpen] = React.useState(false);
  const [emailValue, setEmailValue] = React.useState('');
  const [submitStatus, setSubmitStatus] = React.useState(SUBMIT_STATE.WAITING);
  const [errorMessage, setErrorMessage] = React.useState('');

  function togglePrivacy() {
    setPrivacyOpen(!privacyOpen);
  }

  function updateEmailValue(event) {
    setEmailValue(event.target.value);
  }

  async function mailListSignup(event) {
    event.preventDefault();

    setSubmitStatus(SUBMIT_STATE.PENDING);
    let data = JSON.stringify({Email: emailValue});

    let response = null;
    try {
      response = await fetch('/api/subscriber', {
        method: 'PUT',
        body: data,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
    catch(err) {
      response = {
        status: 500,
        json: async () => {
          return {error: err}
        }
      };
    }

    response = {status: 200};
    
    if(response.status === 200) {
      setSubmitStatus(SUBMIT_STATE.SUCCESS);
    }
    else {
      setSubmitStatus(SUBMIT_STATE.WAITING);
      try {
        let result = await response.json();
        if(result.error) {
          setErrorMessage(result.error);
        }
      }
      catch(err) {
        setErrorMessage('Something went wrong. Please try again later.');
      }
    }
  }

  return (
    <Modal isOpen={isOpen}>
      <PrivacyModal isOpen={privacyOpen} onClose={togglePrivacy}/>
      <div className="p-8">
        <h2 className="text-xl mb-4">Sign up for Ryan's Mailing list</h2>
        <p className="mb-4">
          You'll get emails about book progress, releases and other things I feel may be of interest regarding my work.
          After submitting, you'll get an email with a link to verify your subscription, and you can
          cancel your subscription at any time.
        </p>
        <p className="mb-4">
          Here's my <button className={linkStyle} onClick={togglePrivacy}>privacy policy</button>.
        </p>
        <form onSubmit={mailListSignup}>
          <div className="w-full px-3">
            <label className={labelStyle} htmlFor="email">
              Email Address
            </label>
            <input className={inputStyle} name="email" type="email" required={true} onChange={updateEmailValue}/>
          </div>

          <div className="mt-8">
            {submitStatus === SUBMIT_STATE.WAITING && (
              <button className={greenButtonStyle}>Submit</button>
            )}
            {submitStatus === SUBMIT_STATE.PENDING && (
              <div className="py-1 px-6 fa-2x text-gray-600">
                <FontAwesomeIcon icon={solid('circle-notch')} className="animate-spin"/>
              </div>
            )}
            {submitStatus === SUBMIT_STATE.SUCCESS && (
              <div className="text-center">
                <div className="bg-blue-100 border border-blue-500 text-blue-700 px-4 py-3 rounded-lg text-center" role="alert">
                  <p className="font-bold">Request submitted successfully!</p>
                  <p className="small">Check your email to verify and complete registration.</p>
                </div>
                <button className={grayButtonStyle + " my-4"} onClick={onClose}>Close</button>
              </div>
            )}
            {errorMessage && (
              <div className="bg-red-100 border border-red-500 text-red-700 px-4 py-3 mt-2 rounded-lg text-center" role="alert">
                <p className="font-bold">{errorMessage}</p>
              </div>
            )}
          </div>
        </form>
        <button className={redLinkStyle + " absolute top-4 right-4"} onClick={onClose}>Close</button>
      </div>
    </Modal>
  )
}

export default MailSignup;