
import React from "react";
import Modal from '../components/modal';
import { Link } from 'gatsby';
import { grayButtonStyle, redLinkStyle } from "./layout";
import { linkStyle } from "./layout";

const p_style = "mb-2";
const h_style = "mt-4 text-lg font-bold";

export const PRIVACY_POLICY = (
  <>
    <p className={p_style}>I take your privacy seriously. In accordance
    with applicable privacy laws, I provide this privacy policy notice explaining
    the way your personal information is collected and used.</p>

    <h3 className={h_style}>Collection of Routine Information</h3>
    <p className={p_style}>This website collects basic information about visitors. This information includes,
    but is not limited to, IP addresses, browser details, timestamps and referring
    pages. None of this information can personally identify specific visitors to this
    website. The information is tracked primarily for routine administration and
    maintenance purposes.</p>

    <h3 className={h_style}>Cookies</h3>
    <p className={p_style}>This website doesn't currently use cookies.</p>

    <h3 className={h_style}>Links to Third Party Websites</h3>
    <p className={p_style}>I have included links on this website for your use and reference. I am not
    responsible for the privacy policies on these websites. You should be aware that
    the privacy policies of these websites likely differ from my own.</p>

    <h3 className={h_style}>Security</h3>
    <p className={p_style}>The security of your personal information is important to me, but remember that
    no method of transmission over the Internet, or method of electronic storage, is
    100% secure. While I strive to use commercially acceptable means to protect your
    personal information, I cannot guarantee its absolute security.</p>

    <h3 className={h_style}>Contact & Communication</h3>
    <p className={p_style}>By using this website and/or contacting its owner, you do so at your own discretion
    and provide any such personal details requested at your own risk. Your personal information is kept
    private and stored until a time it is no longer required or has no use.</p>

    <p className={p_style}>This website may use any information submitted to provide you with further
    information about products or services or to assist you in answering any queries you may
    have submitted. This includes using your details to subscribe you to any email newsletter program the
    website operates but only if this was made clear to you and your express permission was granted when
    submitting any form or email process. This is by no means an entire list of your user rights in regard
    to receiving email marketing material. Your details are not passed on to any third parties.</p> 

    <h3 className={h_style}>Changes To This Privacy Policy</h3>
    <p className={p_style}>This Privacy Policy is effective as of Aug 9, 2020 and will remain in effect except
    with respect to any changes in its provisions in the future, which will be in effect
    immediately after being posted on this page.</p>

    <p className={p_style}>I reserve the right to update or change this Privacy Policy at any time and you
    should check this Privacy Policy periodically. If I make any material changes to this
    Privacy Policy, I will notify you either through email or by placing a prominent notice on the website.</p>

    <h3 className={h_style}>Contact Information</h3>
    <p className={p_style}>For any questions or concerns regarding the privacy policy,
    please <Link to="/contact" className={linkStyle}>send me a message</Link> through
    the website or send an email to <a className={linkStyle} href="mailto:webmaster@ryansivek.com">webmaster@ryansivek.com</a>.</p>
  </>
)

const PrivacyModal = ({ isOpen, onClose }) => {

  return (
    <Modal isOpen={isOpen}>
      <div className="p-8">
        <h2 className="text-xl text-center font-bold my-8">Privacy Policy</h2>
        {PRIVACY_POLICY}
        <div className="text-center">
          <button className={grayButtonStyle} onClick={onClose}>Close</button>
        </div>
        <button className={redLinkStyle + " absolute top-4 right-4"} onClick={onClose}>Close</button>
      </div>
    </Modal>
  )
}

export default PrivacyModal;