import * as React from "react";
import Layout, { cyanButtonStyle } from '../components/layout';
import MailSignup from '../components/mailsignup';
import { Seo } from "../components/seo"
import {StaticImage} from 'gatsby-plugin-image';
import { Link } from 'gatsby';

// styles
const IndexPage = () => {

  const [mailSignupOpen, setMailSignupOpen] = React.useState(false);

  function toggleMailSignup() {
    setMailSignupOpen(!mailSignupOpen);
  }

  return (
    <Layout pageTitle="Home Page">
      <MailSignup isOpen={mailSignupOpen} onClose={toggleMailSignup}/>
      <main className={"container mx-auto text-center px-4"}>
        <button className={cyanButtonStyle + " mb-8"} onClick={toggleMailSignup}>
          Click here to join my mailing list!
        </button>
        <title>Home Page</title>
        <h2 className="text-2xl mb-8">
          Book 1 of the Source's Edge series is available now! Click below for details.
        </h2>
        <Link to="/sourcesedge/thesecretsoftercast/">
          <div>
            <StaticImage className="w-full" src="../images/cover_sot_3d_composite.png" alt="The Secrets of Tercast"/>
          </div>
        </Link>
      </main>
    </Layout>
  )
};

export default IndexPage;

export const Head = () => (
  <Seo />
)
