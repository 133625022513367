
import * as React from 'react'
import { Component } from 'react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PrivacyPolicy from '../components/privacy';
import { linkStyle } from './layout';

export const labelStyle = "block uppercase tracking-wide text-zinc-700 dark:text-zinc-300 text-xs font-bold mb-2";
export const inputStyle = "appearance-none bg-zinc-100 dark:bg-black block w-full text-gray-700 dark:text-gray-300 border dark:border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white dark:focus:bg-black focus:border-gray-500";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitSuccess: false,
      pendingResponse: false,
      submitted: false,
      formData: {},
      privacyOpen: false,
      errorMessage: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.togglePrivacy = this.togglePrivacy.bind(this);
  }

  togglePrivacy() {
    this.setState({privacyOpen: !this.state.privacyOpen});
  }

  handleChange(event) {
    this.setState({formData: {...this.state.formData, [event.target.name]: event.target.value}});
  }

  async handleSubmit(event) {
    event.preventDefault();
    
    this.setState({
      submitted: true,
      pendingResponse: true
    });

    let response = null;
    try {
      response = await fetch('/api/contact', {
        method: 'POST',
        body: JSON.stringify(this.state.formData),
        headers: {
          'Content-Type': 'application/json',
        },
      })
    }
    catch(err) {
      response = {
        status: 500,
        json: async () => {
            return {error: 'Something went wrong. Please try again later.'};
        }
      };
    }

    this.setState({pendingResponse: false});

    if(response.status === 200) {
      this.setState({submitSuccess: true});
    }
    else {
      try {
        let result = await response.json();
        this.setState({errorMessage: result.error});
      }
      catch(err) {
        this.setState({errorMessage: 'Something went wrong. Please try again later.'});
      }
      this.setState({submitSuccess: false});
    }
  }

  render() {
    return (
      <>
      <form className="w-full px-3" id="contactForm" onSubmit={this.handleSubmit}>
        <PrivacyPolicy isOpen={this.state.privacyOpen} onClose={this.togglePrivacy}/>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label className={labelStyle} htmlFor="gridFirstName">
              First Name
            </label>
            <input className={inputStyle}
              name="FirstName" type="text" required={true} onChange={this.handleChange}/>
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label className={labelStyle} htmlFor="gridLastName">
              Last Name
            </label>
            <input className={inputStyle}
              name="LastName" type="text" required={true} onChange={this.handleChange}/>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label className={labelStyle} htmlFor="gridEmail">
              Email
            </label>
            <input className={inputStyle}
              name="Email" type="email" maxLength={320} required={true} onChange={this.handleChange}/>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label className={labelStyle} htmlFor="gridSubject">
              Subject
            </label>
            <input className={inputStyle}
              name="Subject" type="text" maxLength={50} required={true} onChange={this.handleChange}/>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3 mb-6">
            <label className={labelStyle} htmlFor="gridMessage">
              Message <span className="font-normal">({this.state.formData.Message ? this.state.formData.Message.length : 0} / 1000)</span>
            </label>
            <textarea className={inputStyle + " h-32"}
              name="Message" type="text" maxLength={1000} required={true} onChange={this.handleChange}></textarea>
          </div>
        </div>
        <div>
          {!this.state.submitSuccess && !this.state.pendingResponse && (
            <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Submit</button>
          )}
          {this.state.pendingResponse && (
            <div className="py-1 px-6 fa-2x text-gray-600">
              <FontAwesomeIcon icon={solid('circle-notch')} className="animate-spin"/>
            </div>
          )}
          {this.state.submitted && !this.state.pendingResponse && (
            <div>
              {this.state.submitSuccess ? (
                <div className="my-4 bg-blue-100 border border-blue-500 text-blue-700 px-4 py-3 rounded-lg" role="alert">
                  <p className="font-bold">Message Sent Successfully</p>
                  <p className="text-sm">Thank you!</p>
                </div>
              ) : (
                <div className="my-4 bg-red-100 border border-red-500 text-red-700 px-4 py-3 rounded-lg" role="alert">
                  <p className="font-bold">Unable to Send Message</p>
                  <p className="text-sm">{this.state.errorMessage}</p>
                </div>
              )}
            </div>
          )}
        </div>
      </form>
      <button className={linkStyle + " my-4"} onClick={this.togglePrivacy}>Privacy Policy</button>
      </>
    )
  }
}

export default ContactForm
